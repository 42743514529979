import { faPlus } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Checkbox } from 'components/form/checkbox'
import { FC, useState } from 'react'
import { Layout } from '../../components/Layout'
import { DayCell } from './components/DayCell'
import { DayHeader } from './components/DayHeader'
import { faSidebarFlip } from '@fortawesome/pro-regular-svg-icons'
import { daysOfWeek } from '../../utils/daysOfWeek'
import { WorkWeekTable } from './components/WorkWeekTable'
import { SectionHeader } from '../../components/SectionHeader'
import { AllocationTables } from './components/AllocationTables'
import { TaskPicker } from './components/TaskPicker'
import { useManagedGroupOutletContext } from '../ManagedGroup'
import { useTranslation } from 'react-i18next'

export const WorkWeekAndAllocation: FC = () => {
  const { t } = useTranslation()
  const [hideTaskSidebar, setHideTaskSidebar] = useState(true)
  const {
    addBreak,
    adminManagedGroup,
    breakLines,
    copyDay,
    enableDay,
    handleTaskClick,
  } = useManagedGroupOutletContext()

  const [copiedDay, setCopiedDay] = useState<DayOfWeek>()

  const exitCopyMode = () => {
    setCopiedDay(undefined)
  }

  return (
    <>
      <Layout activeTab="work-week-and-allocation">
        <SectionHeader
          subtitle={t('features.adminManagedGroups.createATimeCardForTheDay')}
          title={t('common.timeCardsTitlePluralized_one')}
        />
        <div className="items-end grid grid-areas-entries grid-cols-entries mt-4">
          <p className="text-sm text-neutral-900">
            {t('features.adminManagedGroups.day')}
          </p>
          {daysOfWeek.map((day) => (
            <DayHeader
              key={day}
              day={day}
              isCopyable={copiedDay === undefined}
              isCopied={copiedDay === day}
              isPastable={copiedDay !== day}
              onCopy={() => setCopiedDay(day)}
              onExit={exitCopyMode}
              onPaste={() => {
                if (copiedDay === undefined) return

                copyDay(copiedDay, day)
              }}
            />
          ))}
        </div>
        <div className="h-10 items-center grid grid-areas-entries grid-cols-entries mt-4">
          <p className="text-sm text-neutral-900">
            {t('features.adminManagedGroups.createATimeCard')}
          </p>
          {daysOfWeek.map((day) => (
            <DayCell key={day} day={day} className="flex flex-col items-center">
              <Checkbox
                key={day}
                checked={
                  adminManagedGroup.weekTemplate.dayTemplates[day].enabled
                }
                onChange={(e) => enableDay(day, e.target.checked)}
              />
            </DayCell>
          ))}
        </div>
        <SectionHeader
          buttonContent={
            <>
              <FontAwesomeIcon icon={faPlus} />
              {t('features.adminManagedGroups.addBreak')}
            </>
          }
          className="mt-4"
          onButtonClick={addBreak}
          subtitle={t(
            'features.adminManagedGroups.setStartAndEndTimesForWorkWeek',
          )}
          title={t('features.adminManagedGroups.workWeek')}
        />
        <WorkWeekTable breaks={breakLines} />
        <SectionHeader
          buttonContent={
            <>
              {t('features.adminManagedGroups.openTaskFinder')}
              <FontAwesomeIcon icon={faSidebarFlip} />
            </>
          }
          className="mt-4"
          onButtonClick={() => setHideTaskSidebar(false)}
          subtitle={t('features.adminManagedGroups.allocateTimeToSpecificTask')}
          title={t('features.adminManagedGroups.taskAllocation')}
        />
        <AllocationTables />
      </Layout>
      <TaskPicker
        hidden={hideTaskSidebar}
        onClose={() => setHideTaskSidebar(true)}
        onTaskClick={handleTaskClick}
      />
    </>
  )
}
