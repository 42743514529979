import { faChevronRight } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FC, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { twMerge } from '@lib/tailwind-merge'
import { Item } from '../../../components/Item'
import { Label } from '../../../components/Label'
import { NavItem } from '../../../components/NavItem'
import { OnMultisearchItemClick } from '../types'
import {
  pathForAsset,
  pathForSequence,
  pathForShot,
  pathForTask,
} from '../utils/pathFor'
import { findTask } from '@features/task-picker/helpers'

const annotationClassName =
  'mb-2 text-sm italic font-medium text-neutral-500 mx-9'

interface NavItemResultsProps {
  children: ReactNode
  count: number
  label: string
  onViewAllClick?: () => void
  search?: string
}

export const ResultGroup: FC<NavItemResultsProps> = ({
  children,
  count,
  label,
  onViewAllClick,
  search,
}) => {
  const { t } = useTranslation()

  return (
    <div className="flex flex-col">
      <Label content={label} count={count} className="mt-4 mb-2 px-9" />
      {count === 0 ? (
        <span className={annotationClassName}>
          {t('features.timeLogging.taskSelection.noResultsFound')}
        </span>
      ) : (
        children
      )}
      {count > 10 && onViewAllClick && (
        <button
          className={twMerge(
            annotationClassName,
            'flex flex-row justify-between py-2',
          )}
          onClick={onViewAllClick}
        >
          {t('features.timeLogging.taskSelection.countLabelContainingQuery', {
            count,
            translatedLabel: label,
            query: search,
          })}
          <span className="flex flex-row items-center flex-nowrap whitespace-nowrap">
            {t('features.timeLogging.taskSelection.viewAll')}
            <FontAwesomeIcon icon={faChevronRight} className="ml-2" />
          </span>
        </button>
      )}
      <hr className="mx-5 mt-2 text-neutral-300" />
    </div>
  )
}

interface Props {
  disabledTaskIds: Set<number>
  hidden: boolean
  onItemClick: OnMultisearchItemClick
  onTaskClick: (task: Task, checked: boolean) => void
  onViewAllClick: (type: 'sequences' | 'shots' | 'assets' | 'tasks') => void
  results: MultisearchResult | undefined
  selectedTaskIds: Set<number>
  showLoading: boolean
  search: string
}

export const UniversalSearchResults: FC<Props> = ({
  disabledTaskIds,
  hidden,
  onTaskClick,
  onItemClick,
  onViewAllClick,
  results,
  selectedTaskIds,
  showLoading,
  search,
}) => {
  const { t } = useTranslation()

  const handleViewAllClick = (
    type: 'sequences' | 'shots' | 'assets' | 'tasks',
  ) => {
    onViewAllClick(type)
  }

  if (hidden) return null
  if (showLoading || results === undefined)
    return (
      <span className="my-4 text-sm italic font-medium mx-9 text-neutral-500">
        Loading...
      </span>
    )

  return (
    <>
      <ResultGroup
        count={results.projects.count}
        label={t('common.projects')}
        search={search}
      >
        {results.projects.items.map((project, index) => (
          <NavItem
            key={`projects-${project.id ?? index}`}
            label={project.name}
            onClick={() => onItemClick(project, 'projects')}
          />
        ))}
      </ResultGroup>
      <ResultGroup
        count={results.sequences.count}
        label={t('features.timeLogging.taskSelection.sequences')}
        onViewAllClick={() => handleViewAllClick('sequences')}
        search={search}
      >
        {results.sequences.items.map((sequence, index) => (
          <NavItem
            key={`sequences-${sequence.id ?? index}`}
            label={sequence.label}
            path={pathForSequence(sequence)}
            className="mb-3"
            onClick={() => onItemClick(sequence, 'sequences')}
          />
        ))}
      </ResultGroup>
      <ResultGroup
        count={results.shots.count}
        label={t('features.timeLogging.taskSelection.shots')}
        onViewAllClick={() => handleViewAllClick('shots')}
        search={search}
      >
        {results.shots.items.map((shot, index) => (
          <NavItem
            key={`shots-${shot.id ?? index}`}
            label={shot.label}
            path={pathForShot(shot)}
            className="mb-3"
            onClick={() => onItemClick(shot, 'shots')}
          />
        ))}
      </ResultGroup>
      <ResultGroup
        count={results.assets.count}
        label={t('features.timeLogging.taskSelection.assets')}
        onViewAllClick={() => handleViewAllClick('assets')}
        search={search}
      >
        {results.assets.items.map((asset, index) => (
          <NavItem
            key={`assets-${asset.id ?? index}`}
            label={asset.label}
            path={pathForAsset(asset)}
            className="mb-3"
            onClick={() => onItemClick(asset, 'assets')}
          />
        ))}
      </ResultGroup>
      <ResultGroup
        count={results.tasks.count}
        label={t('features.timeLogging.taskSelection.tasks')}
        onViewAllClick={() => handleViewAllClick('tasks')}
        search={search}
      >
        {results.tasks.items.map((task) => {
          if (task.id === undefined) return null

          return (
            <Item
              item={{
                id: task.id,
                checked: selectedTaskIds.has(task.id),
                disabled: disabledTaskIds.has(task.id),
                label: task.name,
                secondaryLabel: task.id?.toString(),
              }}
              path={pathForTask(task)}
              key={`tasks-${task.id}`}
              onItemClick={(id, checked) =>
                onTaskClick(findTask(results.tasks.items, id), checked)
              }
              className="mb-3 mx-9"
            />
          )
        })}
      </ResultGroup>
    </>
  )
}
