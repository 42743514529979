import { FC, useState } from 'react'
import {
  useReactTable,
  createColumnHelper,
  getCoreRowModel,
  flexRender,
  SortingState,
  getSortedRowModel,
  getFilteredRowModel,
  Row,
  Getter,
  ColumnFiltersState,
  ColumnDef,
} from '@tanstack/react-table'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowDownAZ,
  faArrowUpAZ,
  faChainBroken,
} from '@fortawesome/pro-regular-svg-icons'
import { faEdit, faLink, faUnlink } from '@fortawesome/pro-solid-svg-icons'
import { Label } from 'components/indicators/label'
import classNames from 'classnames'
import { secondsToHHMM } from '@utils/time'
import { AutoApprovalToggle } from '../components/AutoApprovalToggle'
import { IconButton } from 'components/buttons'
import { useAssignApprovers } from '../hooks/useAssignApprovers'
import { useRenameProject } from '../hooks/useRenameProject'
import { TableFilterField } from 'components/tables/TableFilterField'
import { useTranslation } from 'react-i18next'
import { DownloadCsvButton } from 'components/tables'
import { useLinkIntegrationProject } from '../hooks/useLinkIntegrationProject'
import { useUnlinkIntegrationProject } from '../hooks/useUnlinkIntegrationProject'
import { useLinkWorkdayProject } from '../hooks/useLinkWorkdayProject'
import { useUnlinkWorkdayProject } from '../hooks/useUnlinkWorkdayProject'

interface Props {
  projects: AdminProject[]
  projectIntegrations: ProjectIntegration[]
}

const columnHelper = createColumnHelper<AdminProject>()

const findIntegrationLink = (
  project: AdminProject,
  projectIntegration: ProjectIntegration,
) =>
  project.integrationProjectLinks.find(
    (link) => link.projectIntegration.id === projectIntegration.id,
  )

export const Table: FC<Props> = ({ projects, projectIntegrations }) => {
  const { t } = useTranslation()
  const [sorting, setSorting] = useState<SortingState>([
    {
      id: 'name',
      desc: false,
    },
  ])
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])

  const { assignApprovers } = useAssignApprovers()
  const { renameProject } = useRenameProject()
  const { linkIntegrationProject } = useLinkIntegrationProject()
  const { unlinkIntegrationProject } = useUnlinkIntegrationProject()
  const { linkWorkdayProject } = useLinkWorkdayProject()
  const { unlinkWorkdayProject } = useUnlinkWorkdayProject()

  const integrationColumns = projectIntegrations
    .sort((a, b) => `${a.type} ${a.name}`.localeCompare(`${b.type} ${b.name}`))
    .map((integration) => {
      return {
        id: `integration-${integration.id}`,
        header: integration.name,
        accessorFn: (row: AdminProject) => {
          const integrationLink = findIntegrationLink(row, integration)

          return integrationLink
            ? `${integrationLink.sourceProject.name} (${integrationLink.sourceProject.id})`
            : 'unlinked'
        },
        enableColumnFilter: true,
        filterFn: 'includesString',
        cell: ({ row }) => {
          const integrationLink = findIntegrationLink(row.original, integration)

          return integrationLink ? (
            <div className="flex justify-between items-center gap-1">
              {integrationLink.sourceProject.name}
              <div>
                <span className="text-neutral-500 font-light text-xs group-hover/row:hidden">
                  {integrationLink.sourceProject.id}
                </span>
                <IconButton
                  title={t('features.projectsAdmin.unlinkProject')}
                  icon={faUnlink}
                  className="hidden w-8 h-8 ml-auto border group-hover/row:block text-neutral-900 border-neutral-900 hover:bg-neutral-900 enabled:hover:text-white"
                  onClick={() =>
                    unlinkIntegrationProject(row.original, integrationLink)
                  }
                  disabled={!integrationLink.sourceProject.unlinkable}
                  tooltip={
                    integrationLink.sourceProject.unlinkable
                      ? undefined
                      : t('features.projectsAdmin.projectCannotBeUnlinked')
                  }
                  tooltipProps={{ delayShow: 300 }}
                />
              </div>
            </div>
          ) : (
            <div className="flex text-neutral-300 justify-between items-center">
              {t('features.projectsAdmin.unlinked')}

              <IconButton
                title={t('features.projectsAdmin.linkProject')}
                icon={faLink}
                className="hidden w-8 h-8 ml-auto border group-hover/row:block text-neutral-900 border-neutral-900 hover:bg-neutral-900 hover:text-white"
                onClick={() =>
                  linkIntegrationProject(row.original, integration)
                }
              />
            </div>
          )
        },
      } as ColumnDef<AdminProject>
    })

  const columns = [
    columnHelper.accessor('name', {
      header: t('features.projectsAdmin.projectName'),
      filterFn: 'includesString',
      cell: ({ row, getValue }) => (
        <div className="flex items-center justify-between">
          {getValue()}
          <IconButton
            title={t('features.projectsAdmin.renameProject')}
            icon={faEdit}
            className="invisible w-8 h-8 ml-auto border group-hover/row:visible text-neutral-900 border-neutral-900 hover:bg-neutral-900 hover:text-white"
            onClick={() => renameProject(row.original)}
          />
        </div>
      ),
    }),
    ...integrationColumns,
    {
      id: 'workday-project',
      header: t('features.projectsAdmin.workdayProject'),
      accessorFn: (row: AdminProject) =>
        row.workdayProject
          ? `${row.workdayProject.name} (${row.workdayProject.id})`
          : 'unlinked',
      enableColumnFilter: true,
      filterFn: 'includesString',
      cell: ({ row }) =>
        row.original.workdayProject ? (
          <div>
            <div className="flex justify-between items-center gap-1">
              {row.original.workdayProject.name}

              <span className="text-neutral-500 font-light text-xs group-hover/row:hidden">
                {row.original.workdayProject.id}
              </span>

              <IconButton
                title={t('features.projectsAdmin.unlinkProject')}
                icon={faUnlink}
                className="hidden w-8 h-8 ml-auto border group-hover/row:block text-neutral-900 border-neutral-900 hover:bg-neutral-900 enabled:hover:text-white"
                onClick={() => unlinkWorkdayProject(row.original)}
              />
            </div>
            {(row.original.workdayProject.startDate ||
              row.original.workdayProject.endDate) && (
              <div className="text-xs font-light text-neutral-500">
                {row.original.workdayProject.startDate?.toLocaleString() ?? '?'}{' '}
                - {row.original.workdayProject.endDate?.toLocaleString() ?? '?'}
              </div>
            )}
          </div>
        ) : (
          <div className="flex items-center">
            <Label
              color="error"
              icon={faChainBroken}
              className="w-24"
              borderShade={200}
            >
              {t('features.projectsAdmin.unlinked')}
            </Label>

            <IconButton
              title={t('features.projectsAdmin.linkProject')}
              icon={faLink}
              className="hidden w-8 h-8 ml-auto border group-hover/row:block text-neutral-900 border-neutral-900 hover:bg-neutral-900 hover:text-white"
              onClick={() => linkWorkdayProject(row.original)}
            />
          </div>
        ),
    } as ColumnDef<AdminProject>,
    {
      id: 'approvers',
      header: t('common.approvers'),
      accessorFn: (row: AdminProject) =>
        row.approverUsers.map((user) => user.name).join(', '),
      enableColumnFilter: true,
      filterFn: 'includesString',
      cell: ({
        row,
        getValue,
      }: {
        row: Row<AdminProject>
        getValue: Getter<string>
      }) => (
        <div className="flex items-center justify-between">
          {getValue()}
          <IconButton
            title={
              t('features.projectsAdmin.manageApprovers', {
                name: row.original.name,
              }) ?? ''
            }
            icon={faEdit}
            className="invisible w-8 h-8 ml-auto border group-hover/row:visible text-neutral-900 border-neutral-900 hover:bg-neutral-900 hover:text-white"
            onClick={() => assignApprovers(row.original)}
          />
        </div>
      ),
    } as ColumnDef<AdminProject>,
    {
      id: 'archived',
      header: t('features.projectsAdmin.status'),
      accessorFn: (row: AdminProject) =>
        row.archivedAt
          ? t('features.projectsAdmin.archived')
          : t('features.projectsAdmin.active'),
      filterFn: 'equalsString',
    } as ColumnDef<AdminProject>,
    columnHelper.accessor('totalSecondsLogged', {
      header: t('features.projectsAdmin.totalTimeLogged'),
      cell: ({ getValue }) => secondsToHHMM(getValue()),
    }),
    {
      id: 'autoApproval',
      header: t('features.projectsAdmin.autoApproval'),
      accessorFn: (row: AdminProject) =>
        row.autoApproval
          ? t('features.projectsAdmin.enabled')
          : t('features.projectsAdmin.disabled'),
      cell: ({ row }: { row: Row<AdminProject> }) => (
        <AutoApprovalToggle project={row.original} />
      ),
      filterFn: 'equalsString',
    } as ColumnDef<AdminProject>,
  ]

  const table = useReactTable({
    data: projects,
    columns,
    state: {
      sorting,
      columnFilters,
    },
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onSortingChange: setSorting,
  })

  const unlinkedProjects = table
    .getRowModel()
    .rows.filter((row) => row.original.workdayProject === null)

  return (
    <div className="px-8 pt-6 flex flex-col flex-grow">
      <div className="flex justify-between mb-2">
        <div>
          <div className="text-lg">
            {t('features.projectsAdmin.projectCountAndTotal', {
              count: table.getRowModel().rows.length,
              total: projects.length,
            })}
          </div>
          <div className="my-1 text-xs text-neutral-600">
            {t('features.projectsAdmin.projectsWithWorkdayLinkingIssues', {
              count: unlinkedProjects.length,
            })}
          </div>
        </div>

        <div>
          <DownloadCsvButton table={table} filename={t('common.projects')} />
        </div>
      </div>

      <div className="relative flex-grow">
        <div className="absolute top-0 bottom-0 left-0 right-0 overflow-auto">
          <table className="text-sm min-w-full">
            <thead className="sticky top-0 shadow z-10">
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <th
                      key={header.id}
                      className="p-4 px-4 py-6 font-semibold text-left border border-t-0 cursor-pointer bg-neutral-100 text-neutral-900 border-neutral-300 hover:bg-neutral-200 first-of-type:border-l-0 last-of-type:border-r-0"
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      <div className="flex justify-between">
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}

                        {header.column.getIsSorted() && (
                          <FontAwesomeIcon
                            icon={
                              header.column.getIsSorted() === 'asc'
                                ? faArrowDownAZ
                                : faArrowUpAZ
                            }
                          />
                        )}
                      </div>

                      {header.column.getCanFilter() && (
                        <div className="max-w-sm">
                          <TableFilterField
                            table={table}
                            column={header.column}
                          />
                        </div>
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row) => (
                <tr key={row.id} className={classNames('group/row')}>
                  {row.getVisibleCells().map((cell) => (
                    <td
                      key={cell.id}
                      className="px-4 py-5 border border-neutral-300 first-of-type:border-l-0 last-of-type:border-r-0"
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}
