import { faCopy, faPaste } from '@fortawesome/pro-regular-svg-icons'
import {
  faArrowLeftFromLine,
  faCircleCheck,
  faExclamationTriangle,
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconButton } from 'components/buttons'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { twMerge } from 'tailwind-merge'
import { BreakValidationErrors, ValidationErrors } from '../../../types'
import { useManagedGroupOutletContext } from '../../ManagedGroup'
import { DayCell } from './DayCell'

const CopyPasteIcon: FC<{
  className?: string
  isCopyable: boolean
  isCopied: boolean
  isPastable: boolean
  onCopy: () => void
  onExit: () => void
  onPaste: () => void
}> = ({
  className,
  isCopyable,
  isCopied,
  isPastable,
  onCopy,
  onExit,
  onPaste,
}) => {
  const { t } = useTranslation()

  if (isCopyable)
    return (
      <IconButton
        icon={faCopy}
        className={twMerge('text-neutral-600', className)}
        onClick={onCopy}
        title={t('features.adminManagedGroups.copy')}
      />
    )

  if (isCopied)
    return (
      <IconButton
        className={twMerge('text-neutral-600', className)}
        icon={faArrowLeftFromLine}
        onClick={onExit}
        title={t('features.adminManagedGroups.exitCopyMode')}
      />
    )

  if (isPastable)
    return (
      <IconButton
        icon={faPaste}
        className={twMerge('text-neutral-600', className)}
        onClick={onPaste}
        title={t('features.adminManagedGroups.paste')}
      />
    )

  return null
}

const Icon: FC<{
  className?: string
  enabled: boolean
  errors: (ValidationErrors | BreakValidationErrors)[]
  submitted: boolean
}> = ({ className, enabled, errors, submitted }) => {
  if (submitted && errors.length > 0)
    return (
      <FontAwesomeIcon
        icon={faExclamationTriangle}
        className={twMerge('text-error-500', className)}
      />
    )

  if (enabled && errors.length === 0)
    return (
      <FontAwesomeIcon
        icon={faCircleCheck}
        className={twMerge('text-success-500', className)}
      />
    )

  if (enabled)
    return (
      <FontAwesomeIcon
        icon={faCircleCheck}
        className={twMerge('text-neutral-300', className)}
      />
    )

  return null
}

interface Props {
  day: DayOfWeek
  isCopyable: boolean
  isCopied: boolean
  isPastable: boolean
  onCopy: () => void
  onExit: () => void
  onPaste: () => void
}

export const DayHeader: FC<Props> = ({
  day,
  isCopyable,
  isCopied,
  isPastable,
  onCopy,
  onExit,
  onPaste,
}) => {
  const { t } = useTranslation()
  const { adminManagedGroup, submitted } = useManagedGroupOutletContext()

  const dayTemplate = adminManagedGroup.weekTemplate.dayTemplates[day]
  const enabled = dayTemplate.enabled

  const errors = [
    ...dayTemplate.errors,
    ...dayTemplate.breaks.flatMap((b) => b.errors),
  ]

  return (
    <DayCell day={day}>
      <div className="flex flex-col justify-end">
        {submitted &&
          errors.map((error) => (
            <span
              className="text-error-500 text-xs text-center font-semibold mb-2"
              key={error}
            >
              {t(`features.adminManagedGroups.validationErrors.${error}`)}
            </span>
          ))}
        <div className="flex flex-row items-center justify-center">
          {enabled && (
            <CopyPasteIcon
              className="mr-1"
              isCopyable={isCopyable}
              isCopied={isCopied}
              isPastable={isPastable}
              onCopy={onCopy}
              onExit={onExit}
              onPaste={onPaste}
            />
          )}
          <label className="text-neutral-900 text-sm font-semibold">
            {t(`common.dayOfWeek.${day}.full`)}
          </label>
          <Icon
            className="ml-2"
            enabled={enabled}
            errors={errors}
            submitted={submitted}
          />
        </div>
      </div>
    </DayCell>
  )
}
