import { useDebounce } from '@hooks/useDebounce'
import { useSearchTasks } from '@hooks/useSearchTasks'
import { FC, ReactNode } from 'react'
import { Item, SkeletonItem } from '../../../components/Item'
import { Column } from '../Column'
import { getItemsAndTotalCount } from '../utils/getItemsAndTotalCount'

interface Props {
  children?: ReactNode
  disabledTaskIds: Set<number>
  integrationId: number
  onItemCountChange: (count?: number) => void
  onSearchChange: (search: string) => void
  onTaskClick: (task: Task, checked: boolean) => void
  path: string[]
  projectId: number
  selectedTaskIds: Set<number>
  search?: string
  searchError?: string
  shown: boolean
  heading?: string
  displaySearch?: boolean
  onSearchToggle: (displayed: boolean) => void
}

export const TaskColumn: FC<Props> = ({
  children,
  disabledTaskIds,
  integrationId,
  onItemCountChange,
  onSearchChange,
  onTaskClick,
  path,
  projectId,
  selectedTaskIds,
  search = '',
  searchError,
  shown,
  heading,
  displaySearch,
  onSearchToggle,
}) => {
  const debounced = useDebounce(search)

  const searchResult = useSearchTasks({
    integrationId,
    projectId,
    q: debounced,
    path,
    onCountChange: onItemCountChange,
  })

  if (!shown) return null

  const { items, totalItems } = getItemsAndTotalCount(searchResult)

  return (
    <Column
      heading={heading}
      displaySearch={displaySearch}
      onSearchToggle={onSearchToggle}
      isFetching={searchResult.isFetchingNextPage || searchResult.isLoading}
      isInitiallyLoading={searchResult.isLoading}
      items={items}
      loadMoreItems={() => void searchResult.fetchNextPage()}
      onSearchChange={onSearchChange}
      renderItem={(item) => {
        return (
          <Item
            className="mx-9"
            key={`${item.id ?? ''}-${item.name}`}
            item={{
              checked: selectedTaskIds.has(item.id),
              disabled: disabledTaskIds.has(item.id),
              id: item.id,
              label: item.name,
              secondaryLabel: item.id?.toString(),
            }}
            onItemClick={(_id, checked) => onTaskClick(item, checked)}
          />
        )
      }}
      searchError={searchError}
      searchValue={search}
      skeletonItem={<SkeletonItem />}
      totalItems={totalItems}
    >
      {children}
    </Column>
  )
}
