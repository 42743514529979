import { FC, Fragment, useState } from 'react'
import {
  AllocationTableHeader,
  LeftHeader,
  SideColumn,
  SummaryCell,
  SummaryRow,
  Table,
} from 'components/time-entry-table'
import { abbreviateDayOfWeek } from '@utils/abbreviateDayOfWeek'
import { daysOfWeek } from '../../../utils/daysOfWeek'
import { DurationCell } from './DurationCell'
import { useManagedGroupOutletContext } from '../../ManagedGroup'
import { useTranslation } from 'react-i18next'

interface Props {
  projectName: string
  projectIntegrationCode?: string
  tasks: SelectedTask[]
}

export const AllocationTable: FC<Props> = ({
  projectName,
  projectIntegrationCode,
  tasks,
}) => {
  const { t } = useTranslation()
  const { adminManagedGroup, totals, setDuration, submitted } =
    useManagedGroupOutletContext()
  const [expanded, setExpanded] = useState(true)

  return (
    <div className="mt-6">
      <AllocationTableHeader
        expanded={expanded}
        onExpandChange={setExpanded}
        projectName={projectName}
        projectIntegrationCode={projectIntegrationCode}
      />
      {expanded && (
        <>
          <Table className="mt-4">
            {tasks.map((task, index) => (
              <Fragment key={task.id}>
                <SideColumn side="left">
                  <LeftHeader
                    key={task.id}
                    highlight={false}
                    labels={[task.name]}
                    rowIndex={index + 1}
                    showBorder={index !== 0}
                  />
                </SideColumn>
                {daysOfWeek.map((day) => {
                  const dayTemplate =
                    adminManagedGroup.weekTemplate.dayTemplates[day]

                  const duration =
                    dayTemplate.entries.find(
                      (entry) => entry.task.id === task.id,
                    )?.duration ?? 0

                  return (
                    <DurationCell
                      key={`${task.id}-${day}`}
                      day={day}
                      duration={duration}
                      editable={dayTemplate.enabled}
                      isError={submitted && dayTemplate.errors.length > 0}
                      onChange={(duration) => {
                        setDuration(day, task, duration)
                      }}
                      rowIndex={index}
                    />
                  )
                })}
              </Fragment>
            ))}
          </Table>
          <SummaryRow>
            <SideColumn side="left">
              <LeftHeader
                highlight={false}
                labels={[t('features.adminManagedGroups.totalAllocatedHours')]}
                rowIndex={1}
              ></LeftHeader>
            </SideColumn>
            {daysOfWeek.map((day, i) => (
              <SummaryCell
                key={day}
                gridClass={`grid-in-${abbreviateDayOfWeek(day)}`}
                total={
                  adminManagedGroup.weekTemplate.dayTemplates[day].totals
                    .allocation
                }
                skipBorder={daysOfWeek.length == i + 1}
              />
            ))}
            <SummaryCell
              gridClass="grid-in-totalByTask"
              total={totals.allocation}
              skipBorder={true}
            />
          </SummaryRow>
        </>
      )}
    </div>
  )
}
