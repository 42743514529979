import { convertSecondsToTwentyFourHourTime } from './convertSecondsToTwentyFourHourTime'

export const convertStartEndTimeSinceMidnightToTwentyFourOurTime = ({
  start,
  end,
}: {
  start: number | null
  end: number | null
}) => {
  const s =
    start === null ? undefined : convertSecondsToTwentyFourHourTime(start)
  const e = end === null ? undefined : convertSecondsToTwentyFourHourTime(end)

  return { start: s, end: e }
}
