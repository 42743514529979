import { col } from '@utils/styles'
import {
  useDeleteTimeCard,
  useTimeCardByDate,
} from '@features/time-logging/hooks/useTimeCardManagement'
import { twMerge } from '@lib/tailwind-merge'
import { FC, useCallback, useState } from 'react'
import { IconButton, MenuButton } from 'components/buttons'
import {
  faChevronDown,
  faChevronUp,
  faNoteSticky,
  faPlusCircle,
  faTrashCan,
  faPenToSquare,
  IconDefinition,
  faLock,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import { useAddTimeCard } from '@features/time-logging/hooks/useAddTimeCard'
import { useModal } from '@hooks/useModal'
import { NoteForm } from './NoteForm'
import { useNoteForDate } from '@hooks/useNotes'
import { faCancel, faComment } from '@fortawesome/pro-solid-svg-icons'
import { useEmployee } from '@hooks/useEmployee'
import { useUserId } from '@features/time-logging/hooks/useUserId'
import { DateTime } from 'luxon'
import {
  useDateTimeToLocaleString,
  useRenderLocaleWeekday,
} from 'hooks/useDateTimeWithLocale'
import { useAuth } from '@hooks/useAuth'
import {
  useCreateCorrection,
  useDeleteCorrection,
} from '@features/time-logging/hooks/useCorrections'
import { DayLockType } from 'types'

const renderIcon = (icon: IconDefinition) => (
  <FontAwesomeIcon
    className="mr-2 text-neutral-500 group-disabled/menu-item:text-neutral-300"
    icon={icon}
  />
)

const content = (icon: IconDefinition, label: string) => (
  <>
    {renderIcon(icon)}
    {label}
  </>
)

interface Props {
  date: DateTime
  hoverDate: DateTime | null
  lock?: DayLock
}

export const Day: FC<Props> = ({ date, hoverDate, lock }) => {
  const { t } = useTranslation()
  const modal = useModal()
  const userId = useUserId()
  const { user: signedInUser } = useAuth()
  const addTimeCardMutation = useAddTimeCard(date)
  const deleteMutation = useDeleteTimeCard()
  const timeCard = useTimeCardByDate(date, { returnNullTimeCard: true })
  const note = useNoteForDate(date, userId)
  const noteBody = note?.body ?? '' // nullish coallesence ensures that text area remains "controlled"
  const noteIsPresent = noteBody.trim().length > 0
  const [dropdownIsHidden, setDropdownIsHidden] = useState(true)
  const [isMouseOver, setIsMouseOver] = useState(false)
  const employeeQuery = useEmployee({ userId })
  const toLocaleString = useDateTimeToLocaleString()
  const renderLocaleWeekday = useRenderLocaleWeekday()
  const createCorrectionMutation = useCreateCorrection(date)
  const deleteCorrectionMutation = useDeleteCorrection()

  const isHovering = hoverDate && date.hasSame(hoverDate, 'day')

  const deleteTimeCard = useCallback(() => {
    modal.confirm({
      content: t('features.timeLogging.deleteTimeCardAndAllAssociatedEntries?'),
      onConfirm: () => deleteMutation.mutate(timeCard.id),
      title: t('features.timeLogging.deleteTimeCard?'),
    })
  }, [timeCard, deleteMutation, modal, t])

  const openModalForm = () => {
    const id = modal.form({
      title: `${t('features.timeLogging.note')} (${toLocaleString({
        weekday: 'short',
        month: 'long',
        day: 'numeric',
      })(date)})`,
      content: (
        <NoteForm
          date={date}
          onCancel={() => modal.close(id)}
          onSave={() => modal.close(id)}
        />
      ),
    })
  }

  const menuItems = [
    {
      disabled:
        timeCard.id !== 0 ||
        date > DateTime.now() ||
        addTimeCardMutation.isLoading ||
        // NOTE: The mere presence of a DayLock implies at minimum a submission lock.
        //       We currently restrict the creation of Time Cards when a day
        //       is submission locked. This will change once the new admin interface
        //       is deployed and we should instead restrict creation of TCs
        //       on dates that are fully locked.
        lock?.type === 'full',
      content: content(faPlusCircle, t('features.timeLogging.addTimeCard')),
      onClick: addTimeCardMutation.mutate,
    },
    {
      disabled:
        timeCard.id === 0 ||
        timeCard.submitted ||
        // Admins can delete future Time Cards, only regular users are prohibitted from doing this
        (!signedInUser?.superAdmin &&
          signedInUser?.administeredFacilities.length == 0 &&
          date > DateTime.now()) ||
        // Corrections cannot be deleted
        timeCard.correction,
      content: content(faTrashCan, t('features.timeLogging.removeTimeCard')),
      onClick: deleteTimeCard,
    },
    {
      disabled:
        (employeeQuery.data?.workdayWorkerId === undefined ||
          (employeeQuery.data && employeeQuery.data.hireDate > date) ||
          (employeeQuery.data?.terminationDate &&
            employeeQuery.data.terminationDate < date)) ??
        undefined,
      content: content(
        faNoteSticky,
        noteIsPresent
          ? t('features.timeLogging.editNote')
          : t('features.timeLogging.addNote'),
      ),
      onClick: openModalForm,
    },
  ]

  // Only Admins can correct Time Cards
  if (signedInUser && signedInUser.administeredFacilities.length > 0) {
    // When there's an existing Time Card, it may only be corrected
    // if it has been sent to payroll OR it has already been corrected,
    // when there is no existing Time Card, a Corrected Time Card
    // may only be created if the day is fully locked.
    if (
      (timeCard.id === 0 && lock?.type === DayLockType.Full) ||
      timeCard.sentToPayrollAt ||
      timeCard.correction
    ) {
      menuItems.push({
        content: content(
          faPenToSquare,
          t('features.timeLogging.makeCorrections'),
        ),
        disabled: timeCard?.correctionState === 'draft', // New corrections cannot be made while there is an in-progress draft correction
        onClick: createCorrectionMutation.mutate,
      })
    }

    // Only draft corrections can be cancelled
    if (timeCard.correctionState === 'draft') {
      menuItems.push({
        content: content(faCancel, t('features.timeLogging.cancelCorrections')),
        disabled: false,
        onClick: () => deleteCorrectionMutation.mutate(timeCard.id),
      })
    }
  }

  return (
    <div
      className={twMerge(
        'pt-1 pb-1 text-center border-t-4 border-t-transparent',
        col(date),
        isHovering && 'border-t-primary-600 bg-neutral-800',
      )}
      data-testid="day"
      onMouseOver={() => setIsMouseOver(true)}
      onMouseLeave={() => setIsMouseOver(false)}
    >
      <div
        className="flex flex-row justify-center text-xs font-[500] uppercase"
        data-testid={date.hasSame(DateTime.now(), 'day') ? 'current-day' : ''}
      >
        <div className="relative w-max">
          <div className="flex items-center absolute right-[110%] top-[-25%]">
            {noteIsPresent && (
              <IconButton
                className="hover:bg-neutral-600"
                icon={faComment}
                onClick={openModalForm}
                title={t('features.timeLogging.viewNoteForDay', {
                  day: renderLocaleWeekday(date),
                })}
              />
            )}
            {lock && (
              <div className="flex items-center w-6 h-6 justify-center">
                <FontAwesomeIcon icon={faLock} title={t('common.locked')} />
              </div>
            )}
          </div>
          {toLocaleString({ weekday: 'short' })(date)}
          {(isMouseOver || !dropdownIsHidden) && (
            <MenuButton
              button={({ menuHidden, toggleMenuHidden }) => (
                <IconButton
                  className="hover:bg-neutral-600"
                  icon={menuHidden ? faChevronDown : faChevronUp}
                  onClick={toggleMenuHidden}
                  title={t('features.timeLogging.performOperationsForDay', {
                    day: renderLocaleWeekday(date),
                  })}
                />
              )}
              className="absolute left-[110%] top-[-25%]"
              menuItems={menuItems}
              onMenuHiddenChange={setDropdownIsHidden}
            />
          )}
        </div>
      </div>
      <div
        className={twMerge(
          'inline-block p-[5px] mt-1 mb-1 rounded text-2xl leading-none font-[500]',
          date.hasSame(DateTime.now(), 'day') && 'bg-white text-neutral-900',
        )}
      >
        {toLocaleString({ day: 'numeric' })(date)}
      </div>
    </div>
  )
}
