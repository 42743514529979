import {
  useAdminManagedGroup,
  useCreateAdminManagedGroup,
  // useDeleteAdminManagedGroup,
  useUpdateAdminManagedGroup,
} from '@hooks/useAdminManagedGroups'
import { range } from '@utils/range'
import { Spinner } from 'components/loaders'
import { Dispatch, FC, memo, SetStateAction, useState } from 'react'
import {
  Outlet,
  useNavigate,
  useOutletContext,
  useParams,
} from 'react-router-dom'
import {
  EnrichedAdminManagedGroup,
  Calculation,
  EnrichedDayTemplate,
} from '../types'
import { toEnrichedAdminManagedGroup } from '../utils/toEnrichedAdminManagedGroup'
import { DateTime } from 'luxon'
import { useAuth } from '@hooks/useAuth'
import { validateStartEnd } from '../utils/validateStartEnd'
import { validateDoesNotOverlap } from '../utils/validateDoesNotOverlap'
import { validateFullyAllocated } from '../utils/validateFullyAllocated'
import { hasErrors } from '../utils/hasErrors'
import { unenrichAdminManagedGroup } from '../utils/unenrichAdminManagedGroup'
import { computeTotals } from '../utils/computeTotals'
import { toast } from '@lib/toasts'
import { useModal } from '@hooks/useModal'
import { useTranslation } from 'react-i18next'
import { convertStartEndTimeSinceMidnightToTwentyFourOurTime } from '../utils/convertStartEndTimeSinceMidnightToTwentyFourOurTime'

interface OutletContext {
  addBreak: () => void
  adminManagedGroup: EnrichedAdminManagedGroup
  approvalGroups: {
    search: string
    setSearch: (search: string) => void
  }
  breakLines: number[]
  copyDay: (fromDay: DayOfWeek, toDay: DayOfWeek) => void
  delete: () => Promise<void>
  duplicate: (options: {
    navigateToTab: 'members' | 'work-week-and-allocation'
  }) => Promise<void>
  enableDay: (day: DayOfWeek, enable: boolean) => void
  handleApprovalGroupClick: (
    approvalGroup: ApprovalGroup,
    options: { selected: boolean },
  ) => void
  handleTaskClick: (task: SelectedTask, selected: boolean) => void
  handleEmployeeClick: (
    worker: BasicWorker,
    options: { selected: boolean },
  ) => void
  isLoading: boolean
  save: (
    name: string,
    options?: {
      navigateToTab?: 'members' | 'work-week-and-allocation'
      duplicate?: boolean
    },
  ) => Promise<void>
  saveDisabled: boolean
  selectedApprovalGroupIds: Set<number>
  selectedWorkerIds: Set<number>
  setBreak: (
    day: DayOfWeek,
    breakNumber: number, // 1..N
    options: { start: number | null; end: number | null },
  ) => void
  setDayStartEnd: (
    day: DayOfWeek,
    options: { start: number | null; end: number | null },
  ) => void
  setDuration: (day: DayOfWeek, task: SelectedTask, duration: number) => void
  setSubmitted: Dispatch<SetStateAction<boolean>>
  submitted: boolean
  totals: Calculation
  updateName: (name: string) => void
  workers: {
    search: string
    setSearch: (search: string) => void
  }
}

export const ManagedGroup: FC = memo(() => {
  const [submitted, setSubmitted] = useState(false)
  const navigate = useNavigate()
  const idParam = Number(useParams()?.id)
  const id = isNaN(idParam) ? 0 : idParam
  const modal = useModal()
  const { t } = useTranslation()

  const { user } = useAuth()

  if (!user) throw new Error('Unreachable: User is not authenticated')

  const [approvalGroupSearch, setApprovalGroupSearch] = useState('')
  const [workerSearch, setWorkerSearch] = useState('')

  const [adminManagedGroup, setAdminManagedGroupOrginal] =
    useState<EnrichedAdminManagedGroup>(
      toEnrichedAdminManagedGroup({
        id: 0,
        name: '',
        approvalGroups: [],
        employees: [],
        weekTemplate: {
          dayTemplates: [],
          taskSelections: [],
        },
        creator: {
          id: user.id,
          name: user.name,
          email: 'foo',
        },
        createdAt: DateTime.now(),
        updatedAt: DateTime.now(),
      }),
    )

  const setAdminManagedGroup = (
    newAdminManagedGroup:
      | EnrichedAdminManagedGroup
      | ((
          adminManagedGroup: EnrichedAdminManagedGroup,
        ) => EnrichedAdminManagedGroup),
  ) => {
    const updated =
      typeof newAdminManagedGroup === 'function'
        ? newAdminManagedGroup(adminManagedGroup)
        : newAdminManagedGroup

    const enrichedAdminManagedGroup: EnrichedAdminManagedGroup = {
      ...updated,
      weekTemplate: {
        ...updated.weekTemplate,
        dayTemplates: Object.fromEntries(
          Object.entries(updated.weekTemplate.dayTemplates).map(
            ([day, dayTemplate]) => {
              const totals = computeTotals(dayTemplate)
              return [
                day as DayOfWeek,
                {
                  ...dayTemplate,
                  totals,
                  errors: dayTemplate.enabled
                    ? [
                        ...validateStartEnd(dayTemplate),
                        ...validateFullyAllocated(totals),
                      ]
                    : [],
                  breaks: dayTemplate.breaks.map((breakTemplate, i) => ({
                    ...breakTemplate,
                    errors: dayTemplate.enabled
                      ? [
                          ...validateStartEnd(breakTemplate),
                          ...validateDoesNotOverlap(breakTemplate, [
                            ...dayTemplate.breaks.slice(0, i),
                            ...dayTemplate.breaks.slice(i + 1),
                          ]),
                        ]
                      : [],
                  })),
                },
              ]
            },
          ),
        ) as Record<DayOfWeek, EnrichedDayTemplate>,
      },
    }

    enrichedAdminManagedGroup.isInvalid = hasErrors(enrichedAdminManagedGroup)

    setAdminManagedGroupOrginal(enrichedAdminManagedGroup)
  }

  const [breakLines, setBreakLines] = useState<number[]>([])

  const queryResult = useAdminManagedGroup(id, {
    enabled: id !== 0,
    onSuccess: (group) => {
      const adminManagedGroup = toEnrichedAdminManagedGroup(group)
      setAdminManagedGroup(adminManagedGroup)
      setSubmitted(false)

      const maxBreaks = Object.values(
        adminManagedGroup.weekTemplate.dayTemplates,
      ).reduce<number>((acc, dayTemplate) => {
        return Math.max(acc, dayTemplate.breaks.length)
      }, 0)
      setBreakLines(maxBreaks === 0 ? [] : range(1, maxBreaks))
    },
    refetchOnWindowFocus: false, // avoids surprise refetches
  })

  const saveQueryMutation = useCreateAdminManagedGroup()
  const updateQueryMutation = useUpdateAdminManagedGroup()
  // const deleteQueryMutation = useDeleteAdminManagedGroup()

  if (!queryResult.data && id !== 0) return <Spinner />

  const updateDay = (
    day: DayOfWeek,
    templateUpdate:
      | Partial<EnrichedDayTemplate>
      | ((prev: EnrichedDayTemplate) => Partial<EnrichedDayTemplate>),
  ) => {
    setAdminManagedGroup((adminManagedGroup) => {
      const prev = adminManagedGroup.weekTemplate.dayTemplates[day]

      const update =
        typeof templateUpdate === 'function'
          ? templateUpdate(prev)
          : templateUpdate

      return {
        ...adminManagedGroup,
        weekTemplate: {
          ...adminManagedGroup.weekTemplate,
          dayTemplates: {
            ...adminManagedGroup.weekTemplate.dayTemplates,
            [day]: { ...prev, ...update },
          },
        },
      }
    })
  }

  const addBreak = () => {
    setBreakLines((breakLines) => [...breakLines, breakLines.length + 1])
  }

  const enableDay: OutletContext['enableDay'] = (day, enable) => {
    updateDay(day, { enabled: enable })
  }

  const setDayStartEnd: OutletContext['setDayStartEnd'] = (day, options) => {
    const { start, end } =
      convertStartEndTimeSinceMidnightToTwentyFourOurTime(options)

    updateDay(day, { start, end })
  }

  const setBreak: OutletContext['setBreak'] = (day, breakNumber, options) => {
    const { start, end } =
      convertStartEndTimeSinceMidnightToTwentyFourOurTime(options)

    updateDay(day, (prev) => {
      let breaks = [...prev.breaks]

      if (!start || !end) {
        breaks = breaks.filter((_, i) => i !== breakNumber - 1)
      } else {
        const breakTime = breaks[breakNumber - 1]
        breaks[breakNumber - 1] = { ...breakTime, start, end }
      }

      return { breaks }
    })
  }

  const handleTaskClick: OutletContext['handleTaskClick'] = (
    task,
    selected,
  ) => {
    const tasks = adminManagedGroup.weekTemplate.taskSelections

    setAdminManagedGroup((adminManagedGroup) => {
      return {
        ...adminManagedGroup,
        weekTemplate: {
          ...adminManagedGroup.weekTemplate,
          taskSelections: selected
            ? [...tasks, task]
            : tasks.filter((t) => t.id !== task.id),
        },
      }
    })
  }

  const setDuration: OutletContext['setDuration'] = (day, task, duration) => {
    updateDay(day, (prev) => {
      let mutated = false

      const entries = prev.entries.map((entry) => {
        if (entry.task.id !== task.id) return entry

        mutated = true
        return { ...entry, duration }
      })

      if (!mutated) entries.push({ task, duration })

      return { entries }
    })
  }

  const allWorkWeek = Object.values(
    adminManagedGroup.weekTemplate.dayTemplates,
  ).reduce((acc, { totals }) => acc + totals.workWeek, 0)

  const allAllocation = Object.values(
    adminManagedGroup.weekTemplate.dayTemplates,
  ).reduce((acc, { totals }) => acc + totals.allocation, 0)

  const allPercentage = Math.round((allAllocation / allWorkWeek) * 100)

  const totals: OutletContext['totals'] = {
    workWeek: allWorkWeek,
    allocation: allAllocation,
    percentage: allPercentage,
  }

  const selectedApprovalGroupIds = new Set(
    adminManagedGroup.approvalGroups.map((ag) => ag.id),
  )
  const selectedWorkerIds = new Set(
    adminManagedGroup.employees.map((u) => u.workdayWorkerId),
  )

  const handleApprovalGroupClick: OutletContext['handleApprovalGroupClick'] = (
    approvalGroup,
    { selected },
  ) => {
    setAdminManagedGroup((prev) => {
      const approvalGroups = selected
        ? [...prev.approvalGroups, approvalGroup]
        : prev.approvalGroups.filter(({ id }) => id !== approvalGroup.id)

      return { ...prev, approvalGroups }
    })
  }

  const handleEmployeeClick: OutletContext['handleEmployeeClick'] = (
    employee,
    { selected },
  ) => {
    setAdminManagedGroup((prev) => {
      const employees = selected
        ? [...prev.employees, employee]
        : prev.employees.filter(
            ({ workdayWorkerId }) =>
              workdayWorkerId !== employee.workdayWorkerId,
          )

      return { ...prev, employees }
    })
  }

  const noDaysEnabled = !Object.values(
    adminManagedGroup.weekTemplate.dayTemplates,
  ).some((dayTemplate) => dayTemplate.enabled)

  const saveDisabled =
    noDaysEnabled || (submitted && adminManagedGroup.isInvalid)

  const updateName = (name: string) => {
    setAdminManagedGroup((prev) => ({ ...prev, name }))
  }

  const save: OutletContext['save'] = async (
    name: string,
    { navigateToTab = 'members', duplicate = false } = {},
  ) => {
    setSubmitted(true)
    if (adminManagedGroup.isInvalid) {
      modal.alert({
        title: t('features.adminManagedGroups.invalidTimeCard'),
        content: t('features.adminManagedGroups.pleaseEnsureValidTimeCards'),
      })
      return
    }

    const performSave = async () => {
      const { id: newId } = await saveQueryMutation.mutateAsync({
        adminManagedGroup: unenrichAdminManagedGroup({
          ...adminManagedGroup,
          name, // Sending in name to help ensure that the name is updated
        }),
      })

      navigate(`/admin/managed-groups/${newId}/${navigateToTab}`, {
        replace: true,
      })
    }

    const performUpdate = async () => {
      await updateQueryMutation.mutateAsync({
        id: adminManagedGroup.id,
        adminManagedGroup: unenrichAdminManagedGroup({
          ...adminManagedGroup,
          name, // Sending in name to help ensure that the name is updated
        }),
      })
    }

    try {
      if (id === 0 || duplicate) {
        await performSave()
      } else {
        await performUpdate()
      }
      toast({
        variant: 'success',
        title: t('common.saved'),
        content: t(
          'features.adminManagedGroups.successfullySavedManagedGroup',
          { name },
        ),
      })
    } catch {
      toast({
        variant: 'error',
        title: t('common.error'),
        content: t('features.adminManagedGroups.errorWhenSavingManagedGroup', {
          name: adminManagedGroup.name,
        }),
      })
    }
  }

  // const deleteAdminManagedGroup: OutletContext['delete'] = async () => {
  //   if (adminManagedGroup.id === 0) return

  //   try {
  //     await deleteQueryMutation.mutateAsync({ id: adminManagedGroup.id })
  //     toast({
  //       variant: 'success',
  //       title: 'Deleted',
  //       content: t(
  //         'features.adminManagedGroups.successfullyDeletedManagedGroup',
  //         { name: adminManagedGroup.name },
  //       ),
  //     })
  //     navigate('/admin/managed-groups')
  //   } catch (e) {
  //     toast({
  //       variant: 'error',
  //       title: 'Error',
  //       content: t(
  //         'features.adminManagedGroups.errorWhenDeletingManagedGroup',
  //         {
  //           name: adminManagedGroup.name,
  //         },
  //       ),
  //     })
  //     throw e
  //   }
  // }

  const duplicate: OutletContext['duplicate'] = async () => {
    await save(`${adminManagedGroup.name} (Copy)`, { duplicate: true })
  }

  const isLoading =
    queryResult.isFetching ||
    saveQueryMutation.isLoading ||
    updateQueryMutation.isLoading // ||
  // deleteQueryMutation.isLoading

  const copyDay: OutletContext['copyDay'] = (fromDay, toDay) => {
    setAdminManagedGroup((prev) => {
      const fromDayTemplate = prev.weekTemplate.dayTemplates[fromDay]

      return {
        ...prev,
        weekTemplate: {
          ...prev.weekTemplate,
          dayTemplates: {
            ...prev.weekTemplate.dayTemplates,
            [toDay]: {
              ...fromDayTemplate,
              dayOfWeek: toDay,
            },
          },
        },
      }
    })
  }

  const context: OutletContext = {
    adminManagedGroup,
    approvalGroups: {
      search: approvalGroupSearch,
      setSearch: setApprovalGroupSearch,
    },
    breakLines,
    copyDay,
    addBreak,
    enableDay,
    delete: () => Promise.resolve(),
    duplicate,
    handleApprovalGroupClick,
    handleTaskClick,
    handleEmployeeClick,
    isLoading,
    save,
    saveDisabled: saveDisabled || isLoading,
    selectedApprovalGroupIds,
    selectedWorkerIds,
    setBreak,
    setDayStartEnd,
    setDuration,
    setSubmitted,
    submitted,
    totals,
    updateName,
    workers: {
      search: workerSearch,
      setSearch: setWorkerSearch,
    },
  }

  return <Outlet context={context} />
})

ManagedGroup.displayName = 'ManagedGroup'

export const useManagedGroupOutletContext = () => {
  return useOutletContext<OutletContext>()
}
