import { Item } from './types'

interface TaskLike {
  id: number
  selected: boolean
  disabled: boolean
  name: string
}

export const itemForTask = (task: TaskLike): Item => {
  return {
    id: task.id,
    checked: task.selected,
    disabled: task.disabled,
    label: task.name,
    secondaryLabel: task.id.toString(),
  }
}

export const enrichTasks = <T extends { id: number }>(
  tasks: T[],
  disabledTaskIds: Set<number>,
  selectedTaskIds: Set<number>,
): (T & { disabled: boolean; selected: boolean })[] => {
  return tasks.map((task) => ({
    ...task,
    disabled: disabledTaskIds.has(task.id),
    selected: selectedTaskIds.has(task.id),
  }))
}

export const includes = (search: string) => (string: string | null) => {
  return string?.toLowerCase()?.includes(search)
}

export const findTask = <T extends Task>(tasks: T[], id: number) => {
  const task = tasks.find((task) => task.id === id)

  if (!task) throw new Error(`Unreachable: Task with id ${id} not found`)

  return task
}
