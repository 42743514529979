import { EnrichedAdminManagedGroup, EnrichedDayTemplate } from '../types'
import { computeTotals } from './computeTotals'
import { daysOfWeek } from './daysOfWeek'

export const toEnrichedAdminManagedGroup = (
  adminManagedGroup: AdminManagedGroup,
): EnrichedAdminManagedGroup => {
  const dayTemplates = daysOfWeek.reduce<{
    [day in DayOfWeek]: EnrichedDayTemplate
  }>(
    (acc, dayOfWeek) => ({
      ...acc,
      [dayOfWeek]: {
        dayOfWeek,
        breaks: [],
        entries: [],
        enabled: false,
        errors: [],
        totals: {
          workWeek: 0,
          allocation: 0,
          percentage: 0,
        },
      },
    }),
    {} as { [dayOfWeek in DayOfWeek]: EnrichedDayTemplate },
  )

  adminManagedGroup.weekTemplate.dayTemplates.forEach((dayTemplate) => {
    dayTemplates[dayTemplate.dayOfWeek] = {
      ...dayTemplate,
      breaks: dayTemplate.breaks.map((breakTemplate) => ({
        ...breakTemplate,
        errors: [],
      })),
      enabled: true,
      errors: [],
      totals: computeTotals(dayTemplate),
    }
  })

  return {
    ...adminManagedGroup,
    isInvalid: false,
    weekTemplate: {
      dayTemplates,
      taskSelections: adminManagedGroup.weekTemplate.taskSelections,
    },
  }
}
