import { FC, useState, useMemo } from 'react'
import { SearchSelection } from 'components/form/search-selection'
import { useTranslation } from 'react-i18next'
import { UseQueryResult } from '@tanstack/react-query'

interface GenericProject {
  id: number
  name: string
}

type Props<T> = {
  onSelect: (project: T) => void
  projectsQuery: UseQueryResult<T[], unknown>
  itemDisabled?: (item: T) => boolean
}

const ProjectItem: FC<{
  name: string
  onClick: () => void
  disabled?: boolean
}> = ({ name, onClick, disabled = false }) => {
  return (
    <button
      title={name}
      className="flex flex-row my-1 border-l-2 border-l-transparent pl-1 enabled:hover:border-l-primary-500 disabled:opacity-50 disabled:cursor-not-allowed"
      disabled={disabled}
      onClick={onClick}
    >
      <div className="flex flex-col">
        <span className="w-full text-sm text-left text-hyperlink-blue">
          {name}
        </span>
      </div>
    </button>
  )
}

// A generalized picker for selecting projects from remote sources,
// e.g. ProjectIntegrations, Workday,
// in contexts where up to one Project may be selected
// (other existing pickers are better suited for multi-selection use-cases)
export function RemoteProjectPicker<T extends GenericProject>({
  onSelect,
  projectsQuery,
  itemDisabled = () => false,
}: Props<T>) {
  const { t } = useTranslation()
  const [search, setSearch] = useState('')

  const projects = useMemo(() => projectsQuery.data ?? [], [projectsQuery.data])

  const filteredProjects = useMemo(
    () =>
      projects.filter((project) =>
        project.name.toLowerCase().trim().includes(search.toLowerCase()),
      ),
    [projects, search],
  )

  return (
    <SearchSelection
      expectedItemHeight={40}
      items={filteredProjects}
      isFetching={projectsQuery.isFetching}
      isInitiallyLoading={projectsQuery.data === undefined}
      onSearch={setSearch}
      placeholder={t('common.search')}
      renderItem={(project) => (
        <ProjectItem
          name={project.name}
          onClick={() => onSelect(project)}
          disabled={itemDisabled(project)}
        />
      )}
      totalItems={filteredProjects.length}
      value={search}
    />
  )
}
