import {
  extractSortedProjectsAndProjectIntegrations,
  groupTaskByProjectAndIntegrationId,
  taskGroupKey,
} from '@utils/groupTaskbyProjectAndIntegrationId'
import { EmptyAllocationBanner } from 'components/time-entry-table'
import { FC, memo } from 'react'
import { useTranslation } from 'react-i18next'
import { useManagedGroupOutletContext } from '../../ManagedGroup'
import { AllocationTable } from './AllocationTable'

export const AllocationTables: FC = memo(() => {
  const { t } = useTranslation()
  const { adminManagedGroup } = useManagedGroupOutletContext()

  const adminProject = {
    id: 0,
    name: t('common.nonProject'),
    isAdminProject: true,
  }

  const tasks = adminManagedGroup.weekTemplate.taskSelections

  const grouped = groupTaskByProjectAndIntegrationId(tasks)
  const projectsAndIntegrations = extractSortedProjectsAndProjectIntegrations(
    grouped,
    adminProject,
  )

  if (projectsAndIntegrations.length === 0)
    return (
      <EmptyAllocationBanner
        body={t('features.adminManagedGroups.useTaskSidebarToAddTasks')}
      />
    )

  return (
    <>
      {projectsAndIntegrations.map(({ project, projectIntegration }) => {
        const tasks = grouped[taskGroupKey({ project, projectIntegration })]

        return (
          <AllocationTable
            key={taskGroupKey({ project, projectIntegration })}
            projectName={project.name}
            projectIntegrationCode={projectIntegration?.code}
            tasks={tasks}
          />
        )
      })}
    </>
  )
})

AllocationTables.displayName = 'AllocationTables'
