import { Calculation, EnrichedDayTemplate } from '../types'
import { computeAllocationTotal } from './computeAllocationTotal'
import { computeWorkdayTotal } from './computeWorkdayTotal'

export const computeTotals = (
  dayTemplate: DayTemplate | EnrichedDayTemplate,
): Calculation => {
  const workWeek = computeWorkdayTotal(dayTemplate)
  const allocation = computeAllocationTotal(dayTemplate)
  const percentage = Math.round((allocation / workWeek) * 100)

  return { workWeek, allocation, percentage }
}
