import { convertTwentyFourHourTimeToSeconds } from './convertTwentyFourHourTimeToSeconds'

export const computeTwentyFourHourTimeDiff = (
  start: TwentyFourHourTime,
  end: TwentyFourHourTime,
) => {
  return (
    convertTwentyFourHourTimeToSeconds(end) -
    convertTwentyFourHourTimeToSeconds(start)
  )
}
