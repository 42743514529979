import { useMultisearchByType } from '@hooks/useMultisearch'
import { InfiniteScrollList } from 'components/containers/infinite-scroll-list'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Item, SkeletonItem } from '../../../components/Item'
import { Label } from '../../../components/Label'
import { NavItem, NavItemSkeleton } from '../../../components/NavItem'
import { OnMultisearchItemClick } from '../types'
import { getItemsAndTotalCount } from '../utils/getItemsAndTotalCount'
import { pathFor, pathForShot, pathForTask } from '../utils/pathFor'

interface Props {
  disabledTaskIds: Set<number>
  onItemClick: OnMultisearchItemClick
  onTaskClick: (task: Task, checked: boolean) => void
  search: string
  selectedTaskIds: Set<number>
  viewing: 'sequences' | 'shots' | 'assets' | 'tasks'
}

export const ViewingAll: FC<Props> = ({
  disabledTaskIds,
  onItemClick,
  onTaskClick,
  search,
  selectedTaskIds,
  viewing,
}) => {
  const { t } = useTranslation()
  const result = useMultisearchByType({
    type: viewing,
    q: search,
  })

  const { items, totalItems } = getItemsAndTotalCount(result)

  return (
    <>
      <Label
        content={t(`features.timeLogging.taskSelection.${viewing}`).toString()}
        count={totalItems ?? 0}
        hideCount={totalItems === undefined}
        className="mt-4 mb-2 px-9"
      />
      <InfiniteScrollList
        items={items}
        loadMore={() => void result.fetchNextPage()}
        isFetching={result.isFetching || result.isLoading}
        isInitiallyLoading={result.isInitialLoading}
        renderItem={(item) => {
          return 'displayPath' in item ? (
            <Item
              className="mx-9"
              key={`${item.id ?? ''}-${item.name}`}
              item={{
                checked: selectedTaskIds.has(item.id),
                disabled: disabledTaskIds.has(item.id),
                id: item.id,
                label: item.name,
                secondaryLabel: item.id?.toString(),
              }}
              path={pathForTask(item)}
              onItemClick={(_item, checked) => onTaskClick(item, checked)}
            />
          ) : (
            <NavItem
              key={`${item.id ?? ''}-${item.label}`}
              label={item.label}
              path={
                viewing === 'shots'
                  ? pathForShot(item as Shot)
                  : pathFor(item as Sequence | Asset)
              }
              onClick={() => {
                if (viewing === 'tasks') return

                onItemClick(item, viewing)
              }}
              showImage={false}
              skip={false}
            />
          )
        }}
        expectedItemHeight={40}
        totalItems={totalItems}
        skeletonItem={
          viewing === 'tasks' ? <SkeletonItem /> : <NavItemSkeleton />
        }
      />
    </>
  )
}
