import { FC } from 'react'
import { Group as GroupType } from '../types'
import { Item, Props as ItemProps } from './Item'
import { Label } from './Label'

export type GroupProps = Omit<GroupType, 'id'> & {
  collapsed: boolean
  onCollapse: () => void
  onExpand: () => void
  onItemClick: ItemProps['onItemClick']
}

export const Group: FC<GroupProps> = ({
  collapsed,
  name,
  onCollapse,
  onExpand,
  onItemClick,
  subGroups,
}) => (
  <section className="flex-col mt-2" key={name}>
    <button onClick={collapsed ? onExpand : onCollapse}>
      <Label
        content={name}
        hideCount={!collapsed}
        count={subGroups.flatMap((s) => s.items).length}
      />
    </button>
    {(collapsed ? [] : subGroups).map(({ name, displayPath = [], items }) => (
      <div key={`${name ?? ''}-${displayPath.join('---')}`} className="mb-2">
        {name && (
          <h3 className="text-xs font-medium text-neutral-500">{name}</h3>
        )}
        {items.map((item, index) => (
          <Item
            key={item.id}
            item={item}
            path={index === 0 ? displayPath : []}
            onItemClick={onItemClick}
          />
        ))}
      </div>
    ))}
  </section>
)
