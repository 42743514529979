import { TaskPicker as TaskPickerFeature } from '@features/task-picker'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

const disabledTaskIds = new Set<number>()
const selectedTaskIds = new Set<number>()

interface Props {
  hidden: boolean
  onClose: () => void
  onTaskClick: (task: SelectedTask, checked: boolean) => void
}

export const TaskPicker: FC<Props> = ({ hidden, onClose, onTaskClick }) => {
  const { t } = useTranslation()

  return (
    <TaskPickerFeature
      disabledTaskIds={disabledTaskIds}
      hidden={hidden}
      onClose={onClose}
      onTaskClick={onTaskClick}
      selectedTaskIds={selectedTaskIds}
      tabs={['all-tasks', 'non-project'] as const}
      title={t('features.adminManagedGroups.taskPicker')}
      subtitle={t('features.adminManagedGroups.selectATask')}
    />
  )
}
