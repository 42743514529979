import { computeTwentyFourHourTimeDiff } from './computeTwentyFourHourTimeDiff'

export const computeWorkdayTotal = (dayTemplate: {
  start?: TwentyFourHourTime
  end?: TwentyFourHourTime
  breaks: { start?: TwentyFourHourTime; end?: TwentyFourHourTime }[]
}) => {
  if (!dayTemplate.start || !dayTemplate.end) return 0

  const totalDay = computeTwentyFourHourTimeDiff(
    dayTemplate.start,
    dayTemplate.end,
  )

  const totalBreaks = dayTemplate.breaks.reduce((acc, { start, end }) => {
    if (!start || !end) return acc

    return acc + computeTwentyFourHourTimeDiff(start, end)
  }, 0)

  return totalDay - totalBreaks
}
